import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';

import RichText from '../../../shared/RichText';
import ShareButtons from '../../ShareButtons';
import { contentPageMaxWidth } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'block',
  },
  content: {},
  container: { position: 'relative' },
  shareButtons: {
    marginBottom: theme.spacing(3),
    textAlign: 'end',
    [theme.breakpoints.up('lg')]: { position: 'absolute', right: '-197px', top: '-20px' },
  },
});

function Content({ classes, className, pageData }) {
  const content = get(pageData, 'content');

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth={contentPageMaxWidth} className={classes.container}>
        <ShareButtons className={classes.shareButtons} pageData={pageData} />
        <RichText textData={content} pageData={pageData} />
      </Container>
    </div>
  );
}

Content.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Content.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export const query = graphql`
  fragment ContentAdvancedPageFragment on ContentfulAdvancedPage {
    __typename
    id
    contentful_id
    title
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
  }

  fragment ContentArticlePageFragment on ContentfulArticlePage {
    __typename
    id
    contentful_id
    title
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
    categories {
      id
      title
      fullPath
    }
  }

  fragment ContentLandingPageFragment on ContentfulLandingPage {
    __typename
    id
    contentful_id
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
  }

  fragment ContentRecipePageFragment on ContentfulRecipePage {
    __typename
    id
    contentful_id
    title
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
    categories {
      id
      title
      fullPath
    }
  }

  fragment ContentBrandFragment on ContentfulBrand {
    __typename
    id
    contentful_id
    title
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
  }

  fragment ContentCategoryFragment on ContentfulCategory {
    __typename
    id
    contentful_id
    title
    menuTitle
    shortDescription
    fullPath
    heroImage {
      ...ContentHeroImageModuleFragment
    }
    categoryIcon {
      id
      cloudinaryImage {
        public_id
      }
      imageAltText
      imageTitleText
    }
  }
  fragment ContentContentfulProductFragment on ContentfulProduct {
    __typename
    id
    contentful_id
    fullPath
    name
    shortDescription
    images {
      ...ContentHeroImageModuleFragment
      ...ContentImageModuleFragment
    }
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
          ...ContentImageModuleFragment
          ...ContentLinkBlockModuleFragment
        }
      }
    }
    categories {
      id
      title
      fullPath
    }
    countryOfOrigin
    availability
    discountPrice
    node_locale
    normalPrice
    packagingType
    whereToBuy
  }

  fragment ContentProductReferenceFragment on ContentfulProductReference {
    __typename
    id
    productName
    contentful_id
    relatedProduct {
      ...ContentProductFragment
    }
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
  }

  fragment ContentProductBrandFragment on ContentfulBrand {
    id
    title
    fullPath
  }

  fragment ContentProductCategoryFragment on ContentfulCategory {
    id
    title
    fullPath
    categoryIcon {
      id
      cloudinaryImage {
        public_id
      }
      imageAltText
      imageTitleText
    }
  }

  fragment ContentProductImageFragment on MagentoProductImage {
    imageAltText
    imageTitleText
    cloudinaryImage {
      public_id
      secure_url
    }
  }

  fragment ContentProductAttributeFragment on MagentoProductAttribute {
    label
    value
  }

  fragment ContentProductAttributesFragment on MagentoProductAttributes {
    country_of_origin {
      ...ContentProductAttributeFragment
    }
    product_classification {
      ...ContentProductAttributeFragment
    }
    description {
      ...ContentProductAttributeFragment
    }
    short_description {
      ...ContentProductAttributeFragment
    }
    base_unit {
      ...ContentProductAttributeFragment
    }
    volume {
      ...ContentProductAttributeFragment
    }
    alcohol_vol {
      ...ContentProductAttributeFragment
    }
    awards {
      ...ContentProductAttributeFragment
    }
    brand_ecomm {
      ...ContentProductAttributeFragment
    }
    closure {
      ...ContentProductAttributeFragment
    }
    packaging_type {
      ...ContentProductAttributeFragment
    }
    taste {
      ...ContentProductAttributeFragment
    }
    subregion {
      ...ContentProductAttributeFragment
    }
    sugar_content {
      ...ContentProductAttributeFragment
    }
    grapes {
      ...ContentProductAttributeFragment
    }
    story {
      ...ContentProductAttributeFragment
    }
    sweetness {
      ...ContentProductAttributeFragment
    }
    how_its_made {
      ...ContentProductAttributeFragment
    }
    monopoly_number {
      ...ContentProductAttributeFragment
    }
    monopoly_price {
      ...ContentProductAttributeFragment
    }
    alko_taste_styles {
      ...ContentProductAttributeFragment
    }
    alko_food_recommendations {
      ...ContentProductAttributeFragment
    }
    viinimaa_labels {
      ...ContentProductAttributeFragment
    }
    alcohol_type {
      ...ContentProductAttributeFragment
    }
    monopoly_url {
      ...ContentProductAttributeFragment
    }
    monopoly_reduced_price {
      ...ContentProductAttributeFragment
    }
    folk_o_folk_food_recommendations {
      ...ContentProductAttributeFragment
    }
    folk_o_folk_labels {
      ...ContentProductAttributeFragment
    }
    monopoly_sortiment {
      ...ContentProductAttributeFragment
    }
    retails_urls {
      ...ContentProductAttributeFragment
    }
    brand_ecomm {
      ...ContentProductAttributeFragment
    }
  }

  fragment ContentProductFragment on MagentoProduct {
    id
    fullPath
    name
    sku
    heroImage {
      ...ContentProductImageFragment
    }
    attributes {
      ...ContentProductAttributesFragment
    }
    categories {
      ...ContentProductCategoryFragment
    }
    brands {
      ...ContentProductBrandFragment
    }
    countryCategories {
      ...ContentProductCategoryFragment
    }
    tasteStyleCategories {
      ...ContentProductCategoryFragment
    }
    foodRecommendationCategories {
      ...ContentProductCategoryFragment
    }
  }

  fragment ContentAssetFragment on ContentfulAsset {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    file {
      url
      contentType
      fileName
    }
  }

  fragment ContentPreparationStepFragment on ContentfulPreparationStep {
    __typename
    id
    contentful_id
    internal {
      type
    }
    preparationStep
    channel {
      channel
    }
  }

  fragment ContentIngredientFragment on ContentfulIngredient {
    __typename
    id
    contentful_id
    internal {
      type
    }
    ingredient
    channel {
      channel
    }
    featuredProduct {
      ...ContentProductReferenceFragment
    }
  }

  fragment ContentPreparationSectionFragment on ContentfulPreparationSection {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    preparationIngredients {
      ...ContentIngredientFragment
    }
    ingredients {
      raw
    }
    preparationSteps {
      raw
      references {
        ... on Node {
          ...ContentPreparationStepFragment
        }
      }
    }
  }

  fragment ContentContentListModuleFragment on ContentfulContentListModule {
    __typename
    id
    contentful_id
    title
    listAppearance
    showTitle
    internal {
      type
    }
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
    contentList {
      id
      title
      image {
        ...ContentImageModuleFragment
      }
      description {
        raw
        references {
          ... on Node {
            ...ContentButtonLinkModuleFragment
          }
        }
      }
      pageReference {
        ...ContentLandingPageFragment
        ...ContentAdvancedPageFragment
        ...ContentArticlePageFragment
        ...ContentRecipePageFragment
        ...ContentBrandFragment
        ...ContentCategoryFragment
        ...ContentProductReferenceFragment
        ...ContentContentfulProductFragment
      }
    }
  }

  fragment ContentButtonLinkModuleFragment on ContentfulButtonLinkModule {
    __typename
    id
    contentful_id
    title
    internal {
      type
    }
    buttonStyle
    buttonAppearance
    buttonSecondaryColor
    buttonPrimaryColor
    openLinkTo
    buttonText {
      ...HyperLinkFragment
    }
    buttonPrimaryColor
    buttonSecondaryColor
    icon
    iconPlacement
  }

  fragment HyperLinkFragment on ContentfulRichText {
    raw
    references {
      ... on Node {
        ...ContentAssetFragment
        ... on ContentfulLandingPage {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulAdvancedPage {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulArticlePage {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulRecipePage {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulBrand {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulCategory {
          __typename
          id
          contentful_id
          fullPath
        }
        ... on ContentfulProduct {
          __typename
          id
          contentful_id
          fullPath
        }
      }
    }
  }

  fragment SocialMediaElementFragment on ContentfulSocialMediaElement {
    __typename
    id
    contentful_id
    title
    internal {
      type
    }
    socialMediaLink
  }

  fragment ContentContactPersonModuleFragment on ContentfulContactPersonModule {
    __typename
    id
    contentful_id
    name
    internal {
      type
    }
    description {
      description
    }
    phoneNumber
    role
    linkedIn
    emailAddress
    cloudinaryImage {
      public_id
    }
  }

  fragment ContentLatestArticlesModuleFragment on ContentfulLatestArticlesModule {
    __typename
    id
    contentful_id
    title
    listAppearance
    contentType
    sliderControls
    fullWidth
    showTitle
    additionalContent {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
    internal {
      type
    }
    backgroundImage {
      cloudinaryImage {
        secure_url
        public_id
      }
    }
    articlePages {
      id
      node_locale
      fullPath
      publishingDate
      title
      shortDescription
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      categories {
        id
        title
        fullPath
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
    brandPages {
      id
      node_locale
      fullPath
      title
      shortDescription
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      categories {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
    categoryPages {
      id
      node_locale
      fullPath
      title
      shortDescription
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      parentCategory {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
    recipePages {
      id
      node_locale
      fullPath
      title
      shortDescription
      publishingDate
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      categories {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
  }

  fragment ContentHeroImageModuleFragment on ContentfulHeroImageModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    imageAltText
    imageTitleText
    descriptionStyle {
      title
    }
    imageLink
    openLinkTo
    photoCredit
    photoCreditColor
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
    cloudinaryImage {
      public_id
      secure_url
    }
    video {
      public_id
      secure_url
      width
      height
      raw_transformation
    }
    videoMobile {
      public_id
      secure_url
      width
      height
      raw_transformation
    }
    imageSpotLink {
      ...ContentImageSpotLinkFragment
    }
  }

  fragment ContentLeadTextModuleFragment on ContentfulLeadTextModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    description {
      raw
    }
  }

  fragment ContentMediaGalleryModuleFragment on ContentfulMediaGalleryModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    usePreview
    galleryImages {
      ...ContentImageModuleFragment
    }
  }

  fragment ContentImageModuleFragment on ContentfulImageModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    imageTitleText
    imageAltText
    imageCaption
    imageLink
    openLinkTo
    photoCredit
    photoCreditColor
    cloudinaryImage {
      public_id
      format
      secure_url
    }
    mobilePaddingTop
    mobilePaddingBottom
    mobilePaddingRight
    mobilePaddingLeft
    desktopPaddingTop
    desktopPaddingBottom
    desktopPaddingRight
    desktopPaddingLeft
    imageSpotLink {
      ...ContentImageSpotLinkFragment
    }
  }

  fragment ContentContentLiftModuleFragment on ContentfulContentLiftModule {
    id
    title
    contentful_id
    node_locale
    image {
      ...ContentImageModuleFragment
    }
    content {
      raw
      references {
        ... on Node {
          ...ContentAudioModuleFragment
          ...ContentfulIdentifierModuleFragment
          ...ContentImageModuleFragment
          ...InspirationalAreaModuleFragment
          ...ContentLeadTextModuleFragment
        }
      }
    }
  }
  fragment ContentImageSpotLinkFragment on ContentfulImageSpotLink {
    id
    title
    contentful_id
    node_locale
    url
    horizontalCoordinateDesktop
    horizontalCoordinateMobile
    verticalCoordinateDesktop
    verticalCoordinateMobile
    content {
      raw
      references {
        ... on Node {
          ...ContentReferenceFragment
        }
      }
    }
  }

  fragment ContentReferenceFragment on Node {
    ...SocialMediaElementFragment
    ...ContentButtonLinkModuleFragment
    ... on ContentfulLandingPage {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulAdvancedPage {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulArticlePage {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulRecipePage {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulBrand {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulCategory {
      __typename
      id
      contentful_id
      fullPath
    }
    ... on ContentfulProduct {
      __typename
      id
      contentful_id
      fullPath
    }
  }

  fragment ContentLinkBlockModuleFragment on ContentfulLinkBlockModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    linkTarget
    blockOrientation
    openLinkTo
    image {
      ...ContentImageModuleFragment
    }
    description {
      raw
    }
  }
  fragment ContentSearchModuleFragment on ContentfulSearchModule {
    title
    contentType
    contentful_id
    internal {
      type
    }
    filterCategories {
      fullPath
      id
      contentful_id
      languageCode
      menuTitle
      title
      shortDescription
      categoryType
    }
    searchPlaceholderText
    searchType {
      title
    }
    showAllResultsByDefault
    node_locale
  }

  fragment ContentIFrameModuleFragment on ContentfulIFrameModule {
    __typename
    contentful_id
    internal {
      type
    }
    url
    title
    minimumHeight
    iFrameType {
      title
    }
  }

  fragment ContentAccordionModuleFragment on ContentfulAccordionModule {
    __typename
    id
    contentful_id
    title
    adminTitle
    subtitle
    internal {
      type
    }
    openByDefault
    description {
      raw
      references {
        ... on Node {
          ...ContentReferenceFragment
        }
      }
    }
  }

  fragment ContentfulIdentifierModuleFragment on ContentfulIdentifierModule {
    __typename
    id
    contentful_id
    title
    internal {
      type
    }
    identifier
  }

  fragment ContentFormModuleFragment on ContentfulFormModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    node_locale
    formType {
      title
      custobarMailingLists
    }
  }
  fragment ContentPopUpModuleFragment on ContentfulPopUpModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    node_locale
    visibilityDelay
    showOnlyOnce
    urlExclusions
    content {
      raw
      references {
        ... on Node {
          ...ContentFormModuleFragment
          ...ContentButtonLinkModuleFragment
        }
      }
    }
  }

  fragment ContentTableModuleFragment on ContentfulTableModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    table {
      columns {
        key
        text
      }
      rows {
        key
        columns {
          key
          text
        }
      }
    }
  }

  fragment ContentVideoModuleFragment on ContentfulVideoModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    youTubeUrl
    title
  }

  fragment ContentProductSliderModuleFragment on ContentfulProductSliderModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    featuredProducts {
      ...ContentProductReferenceFragment
    }
  }

  fragment ContentFeaturedProductModuleFragment on ContentfulFeaturedProductModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    featuredProduct {
      ...ContentProductReferenceFragment
    }
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
  }

  fragment QuickSearchModuleFragment on ContentfulSearchModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    title
    searchType {
      title
    }
  }
  fragment InspirationalAreaModuleFragment on ContentfulInspirationalAreaModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    node_locale
    appearance
    title
    inspirationalElements {
      ...ContentHeroImageModuleFragment
    }
  }
  fragment ContentInstagramFeedModuleFragment on ContentfulInstagramFeedModule {
    __typename
    id
    contentful_id
    internal {
      type
    }
    node_locale
    title
    feedTitle
    instagramAccountUrl
    posts
    postCount
    instagramNodes {
      caption
      id
      mediaType
      preview
      type
      username
      thumbnails {
        src
        config_width
        config_height
      }
      timestamp
      localFile {
        absolutePath
        relativePath
        base
        relativeDirectory

        extension
        childImageSharp {
          fixed(width: 650, height: 650) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }

  fragment ContentAudioModuleFragment on ContentfulAudioModule {
    __typename
    title
    contentful_id
    id
    internal {
      type
    }
    audioFile {
      secure_url
      url
      duration
      public_id
      format
    }
  }
  fragment ContentEventCalendarModuleFragment on ContentfulEventCalendarModule {
    __typename
    internal {
      type
    }
    adminTitle
    title
    id
    contentful_id
    events {
      contentful_id
      id
      __typename
      internal {
        type
      }
      adminTitle
      shortDescription {
        shortDescription
      }
      startDate
      endDate
      name
      url
      description {
        raw
        references {
          ... on Node {
            ...ContentReferenceFragment
            ...ContentImageModuleFragment
          }
        }
      }
    }
  }

  fragment CategoryDescriptionFragment on Node {
    ...ContentBrandFragment
    ...ContentCategoryFragment
    ...ContentAdvancedPageFragment
    ...ContentArticlePageFragment
    ...ContentLandingPageFragment
    ...ContentRecipePageFragment
    ...ContentAssetFragment
  }

  fragment ContentFragment on ContentfulRichText {
    raw
    references {
      ... on Node {
        ...ContentBrandFragment
        ...ContentCategoryFragment
        ...ContentAdvancedPageFragment
        ...ContentArticlePageFragment
        ...ContentLandingPageFragment
        ...ContentRecipePageFragment
        ...ContentContentfulProductFragment
        ...ContentAssetFragment
        ...ContentPreparationSectionFragment
        ...ContentPreparationStepFragment
        ...ContentContentListModuleFragment
        ...ContentButtonLinkModuleFragment
        ...SocialMediaElementFragment
        ...ContentContactPersonModuleFragment
        ...ContentLatestArticlesModuleFragment
        ...ContentHeroImageModuleFragment
        ...ContentLeadTextModuleFragment
        ...ContentLinkBlockModuleFragment
        ...ContentAccordionModuleFragment
        ...ContentImageModuleFragment
        ...ContentMediaGalleryModuleFragment
        ...ContentFormModuleFragment
        ...ContentTableModuleFragment
        ...ContentVideoModuleFragment
        ...ContentProductSliderModuleFragment
        ...ContentFeaturedProductModuleFragment
        ...QuickSearchModuleFragment
        ...InspirationalAreaModuleFragment
        ...ContentSearchModuleFragment
        ...ContentfulIdentifierModuleFragment
        ...ContentAudioModuleFragment
        ...ContentIFrameModuleFragment
        ...ContentInstagramFeedModuleFragment
        ...ContentEventCalendarModuleFragment
        ...ContentContentLiftModuleFragment
      }
    }
  }
`;

export default withStyles(styles)(Content);
