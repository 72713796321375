import { get, isEmpty, isEqual } from 'lodash';

import { variables } from '../sites';

export const createCloudinaryImageUrl = (transformation, publicId, parameterString, format, quality, gravity) => {
  const cloudinaryHost = `https://res.cloudinary.com/${get(variables, 'cloudinary.cloudName')}`;

  if (isEmpty(publicId)) {
    return undefined;
  }

  if (isEqual(format, 'svg') || (!isEmpty(parameterString) && parameterString.includes('c_pad'))) {
    gravity = '';
  } else if (!isEmpty(parameterString) && parameterString.includes('c_fill_pad')) {
    gravity = 'auto';
  }

  const gravityString = gravity ? `,g_${gravity}` : '';

  format = format ?? 'auto';

  if (!isEmpty(parameterString)) {
    // eslint-disable-next-line max-len
    return `${cloudinaryHost}/image/upload/${parameterString}${gravityString}/f_${format}/q_${quality}/${encodeURIComponent(
      publicId,
    )}`;
  }

  if (isEmpty(transformation)) {
    return `${cloudinaryHost}/image/upload/${encodeURIComponent(publicId)}`;
  }

  return `${cloudinaryHost}/image/upload/t_${transformation}/f_${format}/${encodeURIComponent(publicId)}`;
};
