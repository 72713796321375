import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import ContentRow from './ContentRow';

import '../../ContentColumns';

const styles = () => ({
  root: {
    display: 'block',
  },
});

function ContentRows({ classes, className, pageData }) {
  const contentRows = get(pageData, 'contentRows');

  return (
    <div className={clsx(classes.root, className)}>
      {map(contentRows, (rowData, index) => {
        const contentRowId = `${get(rowData, 'id')}-${index}`;
        return <ContentRow key={contentRowId} pageData={pageData} rowData={rowData} />;
      })}
    </div>
  );
}

ContentRows.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentRows.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export const query = graphql`
  fragment ContentfulFontFamilyFragment on ContentfulFontFamily {
    title
    fontFamilyLink
    cssFontFamilyIdentifier
    fontFile {
      id
      public_id
      url
      secure_url
    }
    fontEmbedLink {
      fontEmbedLink
    }
  }

  fragment ContentfulCustomStyleFragment on ContentfulCustomStyle {
    bodyFontColor
    bodyFontFamily {
      ...ContentfulFontFamilyFragment
    }
    headingFontColor
    headingFontFamily {
      ...ContentfulFontFamilyFragment
    }
    linkFontColor
    backgroundColor
    desktopPaddingBottom
    desktopPaddingLeft
    desktopPaddingRight
    desktopPaddingTop
    mobilePaddingBottom
    mobilePaddingLeft
    mobilePaddingRight
    mobilePaddingTop
    marginMobile
    marginDesktop
  }

  fragment ContentRowFragment on ContentfulRowStructure {
    id
    title
    backgroundColor
    columnVerticalAlignment
    fullWidth
    backgroundImage {
      imageAltText
      imageTitleText
      cloudinaryImage {
        secure_url
        public_id
      }
    }
    customRowStyle {
      ...ContentfulCustomStyleFragment
    }
    columns {
      ...ContentColumnFragment
    }
    identifier
  }

  fragment SiteNotificationFragment on ContentfulSiteNotification {
    id
    contentful_id
    title
    description {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
        }
      }
    }
  }

  fragment AnalyticsModuleFragment on ContentfulAnalyticsModule {
    id
    headSection {
      headSection
    }
    bodySection {
      bodySection
    }
  }

  fragment ThemeStyleModuleFragment on ContentfulThemeStyleModule {
    id
    contentful_id
    logo {
      ...ContentImageModuleFragment
    }
    favicon {
      ...ContentImageModuleFragment
    }
    fontFamilyText {
      ...ContentfulFontFamilyFragment
    }
    fontFamilyTitle {
      ...ContentfulFontFamilyFragment
    }
    fontSizeText
    fontSizeTitle
    linkColor
    primaryBrandColor
    title
    titleColor
    textColor
  }

  fragment ContentHeaderFragment on ContentfulHeaderModule {
    title
    headerElements
    headerContent {
      raw
      references {
        ... on Node {
          ...ContentButtonLinkModuleFragment
          ...SocialMediaElementFragment
        }
      }
    }
  }

  fragment themeFragment on ContentfulTheme {
    contentful_id
    themeType
    title
    node_locale
    seoTitle
    channel {
      ...PrimaryChannelFragment
    }
    header {
      ...ContentHeaderFragment
    }
    footer {
      ...ContentRowFragment
    }
    navigation {
      ...TopNavigationFragment
    }
    analytics {
      ...AnalyticsModuleFragment
    }
    globalPopUp {
      ...ContentPopUpModuleFragment
    }
    siteNotification {
      ...SiteNotificationFragment
    }
    themeStyles {
      ...ThemeStyleModuleFragment
    }
    productPageContent {
      ...ContentRowFragment
    }
    maintenanceMode
  }
`;

export default withStyles(styles)(ContentRows);
