import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { Launch } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { purchaseProduct, purchaseProductFromCart } from '../../../../../utils/analyticUtils';

import Button from '../../../Button';
import Visible from '../../../Visible';

const styles = theme => ({
  root: {
    ...theme.palette.button,
    display: 'flex',
    width: '100%',
    padding: '13px 15px',
    fontSize: '12px',
    fontWeight: '700',
    maxWidth: '100%',
    '@media print': {
      display: 'none',
    },
  },
  missingLink: {},
});

function ProductMonopolyLink({ className, classes, productData, location, ...otherProps }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['cb']);

  const monopolyUrl = get(productData, 'attributes.monopoly_url.value');
  const fullPath = get(productData, 'fullPath');

  const retailUrls = useMemo(() => {
    const urls = filter(
      JSON.parse(get(productData, 'attributes.retails_urls.value', '[]')),
      item => !isEmpty(get(item, 'url')),
    );
    return urls;
  }, [productData]);
  const customerToken = cookies.cb ? cookies.cb.token : '';

  const handleButtonClick = useCallback(() => {
    purchaseProduct(productData, customerToken);
    if (location === 'memoryList') {
      purchaseProductFromCart();
    }
  }, [productData, customerToken, location]);

  return (
    <>
      <Visible
        visible={
          (!isEmpty(monopolyUrl) && isEmpty(retailUrls)) || (!isEmpty(monopolyUrl) && location === 'productPage')
        }>
        <Button
          {...otherProps}
          target="_blank"
          className={clsx(classes.root, className)}
          to={monopolyUrl}
          endIcon={<Launch fontSize="small" />}
          onClick={handleButtonClick}>
          {t('ProductMonopolyLink.label')}
        </Button>
      </Visible>
      <Visible
        visible={
          (isEmpty(monopolyUrl) && location !== 'productPage') || (!isEmpty(retailUrls) && location !== 'productPage')
        }>
        <Button
          {...otherProps}
          variant="outlined"
          target="_blank"
          className={clsx(classes.root, className)}
          to={fullPath}
          onClick={handleButtonClick}>
          {t('ProductMonopolyLink.availablity.label')}
        </Button>
      </Visible>
    </>
  );
}

ProductMonopolyLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  location: PropTypes.oneOf(['memoryList', 'productPage']),
};

ProductMonopolyLink.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  location: null,
};

export default withStyles(styles)(ProductMonopolyLink);
