import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Badge, IconButton, withStyles } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';

import { useDialog } from '../../../context/DialogContext';
import { useMemoryList } from '../../../context/MemoryListContext';

const styles = theme => ({
  root: {
    display: 'flex',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 16px',
    },
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  badge: {
    '& span': {
      ...theme.palette.memoryListButton.budge,
    },
  },
});

function MemoryListIconButton({ classes, className }) {
  const { openDialog } = useDialog();
  const { products } = useMemoryList();

  const handleButtonClick = useCallback(() => {
    openDialog('MemoryListDialog');
  }, [openDialog]);

  return (
    <div className={clsx(classes.root, className)}>
      <IconButton className={classes.iconButton} onClick={handleButtonClick}>
        <Badge className={classes.badge} badgeContent={products.length} showZero>
          <ListAlt />
        </Badge>
      </IconButton>
    </div>
  );
}

MemoryListIconButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

MemoryListIconButton.defaultProps = {
  classes: {},
  className: null,
};

export default withStyles(styles)(MemoryListIconButton);
