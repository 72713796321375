import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { withStyles, IconButton, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import { DateTime } from 'luxon';
import Visible from '../../../shared/Visible';
import Link from '../../../navigation/Link';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:hover #_eventName': {
      color: theme.palette.text.primary,
    },
    ...theme.event.root,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateWrapper: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },

  day: { ...theme.event.day },
  month: { ...theme.event.month },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 24px',
  },
  name: {
    ...theme.event.name,
  },
  shortDescription: { ...theme.event.shortDescription },
  icon: { ...theme.event.icon },
});

// eslint-disable-next-line no-unused-vars
function Event({ classes, className, data, richText: RichText }) {
  const name = get(data, 'name');
  const shortDescription = get(data, 'shortDescription.shortDescription');
  // const description = get(data, 'description');
  const start = get(data, 'startDate');
  const end = get(data, 'endDate');
  const url = get(data, 'url');

  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  return (
    <Link to={url} className={classes.link} target="_blank">
      <div className={clsx(classes.root, className)}>
        <div className={classes.wrapper}>
          <div className={classes.dateWrapper}>
            <Typography align="center" className={classes.day}>
              {endDate.day}
            </Typography>
            <Typography align="center" className={classes.month}>
              {startDate.monthShort}
            </Typography>
          </div>
          <div className={classes.textWrapper}>
            <Typography className={classes.name} id="_eventName">
              {name}
            </Typography>
            {shortDescription && <Typography className={classes.shortDescription}>{shortDescription}</Typography>}
          </div>
        </div>
        <Visible hidden={isEmpty(url)}>
          <div>
            <IconButton size="large" className={classes.icon}>
              <ArrowForward fontSize="inherit" />
            </IconButton>
          </div>
        </Visible>
      </div>
    </Link>
  );
}

Event.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
};

Event.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
};

export default withStyles(styles)(Event);
