import React from 'react';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { siteIds, contentfulContentTypes } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaHeader from './viinimaa';
import FolkofolkHeader from './folkofolk';
import SkagerrakHeader from './skagerrak';
import WineworldFinlandHeader from './wineworldFinland';
import LinieAquavitHeader from './linieAquavit';
import SocialwinesHeader from './socialwines';

function Header(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaHeader {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkHeader {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    const pageType = get(props, 'pageData.internal.type');

    return (
      <SkagerrakHeader
        {...props}
        fill="#ffffff"
        backgroundColor={isEqual(pageType, contentfulContentTypes.LandingPage) ? 'transparent' : null}
        backgroundImage="linear-gradient(to bottom, #192639 0%, rgba(25, 38, 57, 0) 100%)"
        showLanguageSwitcher
      />
    );
  }
  if (isEqual(siteId, siteIds.OpAnderson)) {
    return (
      <SkagerrakHeader
        {...props}
        fill="#C61A27"
        backgroundImage="linear-gradient(to bottom, #fbf3e9 0%, rgba(251, 243, 233, 0) 100%)"
        backgroundColor="transparent"
      />
    );
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return (
      <SkagerrakHeader
        {...props}
        fill="#333333"
        width="36"
        height="36"
        backgroundImage="linear-gradient(to bottom, #fffdee 0%, rgba(255, 253, 238, 0) 100%)"
        backgroundColor="transparent"
        hideContent
        showLanguageSwitcher
      />
    );
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return (
      <WineworldFinlandHeader
        {...props}
        mobileMood={false}
        fill="#2e0a2b"
        width="36"
        height="36"
        backgroundImage="linear-gradient(to bottom, rgba(251, 243, 233, 0.8) 0%, rgba(255, 253, 238, 0) 100%)"
        backgroundColor="transparent"
      />
    );
  }
  if (isEqual(siteId, siteIds.Gammeldansk)) {
    return <ViinimaaHeader {...props} mobileMood showLanguageSwitcher />;
  }
  if (isEqual(siteId, siteIds.LinieAquavit)) {
    return <LinieAquavitHeader {...props} showLanguageSwitcher />;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return <ViinimaaHeader {...props} backgroundColor="transparent" showLanguageSwitcher />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <SocialwinesHeader {...props} />;
  }
  if (brandSite) {
    return <ViinimaaHeader {...props} showLanguageSwitcher />;
  }

  return null;
}

export default Header;
