import React from 'react';

import { isEqual } from 'lodash';

import { siteIds, productDefaultImage } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductCard from './viinimaa';

function ProductCard(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductCard {...props} defaultImage={productDefaultImage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductCard {...props} hideMonopolyLink defaultImage={productDefaultImage.Folkofolk} />;
  }

  return null;
}

export default ProductCard;
