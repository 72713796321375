/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, replace, isUndefined } from 'lodash';
import { withStyles } from '@material-ui/core';

import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

import { fill } from '@cloudinary/url-gen/actions/resize';
import { Gravity } from '@cloudinary/url-gen/qualifiers';

import { useWindowSize } from '../../hooks/useWindowSize';
import { breakPoints } from '../../../constants';
import PlayButton from './PlayButton';
import { variables } from '../../../sites';

const styles = () => ({
  video: {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    maxHeight: ({ maxHeight }) => {
      return maxHeight || '920px';
    },
  },
});

const cloudinaryCore = new Cloudinary({
  cloud: {
    cloudName: variables.cloudinary.cloudName,
  },
});

function HeroVideo({ classes, className, data, loop }) {
  const videoPublicIdMobile = get(data, 'videoMobile.0.public_id');
  const videoPublicId = get(data, 'video.0.public_id');
  const videoUrlDesktop = get(data, 'video.0.secure_url');
  const videoUrlMobile = get(data, 'videoMobile.0.secure_url');

  const [videoWidth, setVideoWidth] = useState(1000);
  const [publicID, setPublicID] = useState(videoPublicIdMobile || videoPublicId);

  const formPosterUrl = (URL, width) => {
    return replace(URL, 'f_auto/q_auto', `f_jpg,q_auto,c_fill,w_${width},g_custom,so_0`);
  };

  const [poster, setPoster] = useState(formPosterUrl(videoUrlMobile || videoUrlDesktop, videoWidth));

  const { width } = useWindowSize();

  const playOnLoad = true;
  const videoRef = useRef();

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.lg) {
        setVideoWidth(2600);
      }
      if (width > breakPoints.md && width < breakPoints.lg) {
        setVideoWidth(1600);
      }
      if (width > breakPoints.sm && width < breakPoints.md) {
        setVideoWidth(1200);
      }
      if (width < breakPoints.sm && videoWidth !== 1000) {
        setVideoWidth(1000);
      }

      if (width < breakPoints.md) {
        if (!isUndefined(videoPublicIdMobile)) {
          setPublicID(videoPublicIdMobile);
          setPoster(formPosterUrl(videoUrlMobile, videoWidth));
        } else {
          setPublicID(videoPublicId);
          setPoster(formPosterUrl(videoUrlDesktop, videoWidth));
        }
      } else if (!isUndefined(videoPublicId)) {
        setPublicID(videoPublicId);
        setPoster(formPosterUrl(videoUrlDesktop, videoWidth));
      } else {
        setPublicID(videoPublicIdMobile);
        setPoster(formPosterUrl(videoUrlMobile, videoWidth));
      }
    }
    return () => {
      return null;
    };
  }, [videoPublicId, videoPublicIdMobile, videoUrlDesktop, videoUrlMobile, width, videoWidth]);

  const cldVideo = useMemo(() => {
    return cloudinaryCore.video(publicID);
  }, [publicID]);

  useEffect(() => {
    cldVideo.resize(fill().width(videoWidth).gravity(Gravity.autoGravity().autoFocus()));
  }, [cldVideo, videoWidth]);

  const playPauseVideo = () => {
    const isPlaying =
      videoRef.current.videoRef.current.currentTime > 0 &&
      !videoRef.current.videoRef.current.paused &&
      !videoRef.current.videoRef.current.ended &&
      videoRef.current.videoRef.current.readyState > videoRef.current.videoRef.current.HAVE_CURRENT_DATA;
    if (isPlaying) {
      videoRef.current.videoRef.current.pause();
    } else {
      videoRef.current.videoRef.current.play();
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <PlayButton videoRef={videoRef} playOnLoad={playOnLoad} />
      <AdvancedVideo
        cldVid={cldVideo}
        controls={false}
        autoPlay={playOnLoad}
        poster={poster}
        loop={loop}
        muted
        className={classes.video}
        secure="true"
        ref={videoRef}
        onClick={playPauseVideo}
        playsInline
      />
    </div>
  );
}

HeroVideo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  height: PropTypes.number,
  loop: PropTypes.bool,
};

HeroVideo.defaultProps = {
  classes: {},
  className: null,
  data: null,
  height: 340,
  loop: false,
};

export default withStyles(styles)(HeroVideo);
