import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductMonopolyPrice from './viinimaa';

function ProductMonopolyPrice(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductMonopolyPrice {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductMonopolyPrice {...props} />;
  }

  return null;
}

export default ProductMonopolyPrice;
