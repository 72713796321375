import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { withStyles, Divider, Typography } from '@material-ui/core';

import { productClick } from '../../../../../utils/analyticUtils';

import ThumbnailImage from '../../../ThumbnailImage';
import Link from '../../../../navigation/Link';
import ProductMonopolyLink from '../../ProductMonopolyLink';
import ProductMonopolyPrice from '../../ProductMonopolyPrice';
import ProductLabels from '../../ProductLabels';
import ProductAwards from '../../ProductAwards';
import ProductClassification from '../../ProductClassification';
import ProductCategories from '../../ProductCategories';
import Visible from '../../../Visible';
import Button from '../../../Button';

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
    '@media print': {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },
  imageLink: { position: 'relative' },

  thumbnailImage: {
    margin: theme.spacing(0, 'auto', 2, 'auto'),
  },
  label: {
    margin: '0px',
  },
  newLabel: {
    padding: '0px 0px',
    fontSize: '0.75rem',
  },
  awards: {
    padding: '0px 0px',
  },
  ProductClassificationImage: {
    margin: theme.spacing(1, 0),
  },
  categories: {
    marginBottom: theme.spacing(1),
  },
  name: {
    marginBottom: theme.spacing(2),
    color: 'inherit',
  },
  link: {
    color: 'inherit',
  },
  divider: {
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: '6px',
  },
  countryOfOrigin: {
    color: theme.palette.common.mediumGray2,
    marginBottom: theme.spacing(2),
  },
  monopolyPrice: {
    marginBottom: theme.spacing(2),
  },
  readMoreButton: {
    display: 'flex',
    padding: '13px 15px',
    fontSize: '12px',
    fontWeight: '700',
    '@media print': {
      display: 'none',
    },
  },
});

function ProductCard({ classes, className, productData, hideMonopolyLink, defaultImage }) {
  const { t } = useTranslation();
  const fullPath = get(productData, 'fullPath');
  let heroImage = get(productData, 'heroImage');
  if (isEmpty(get(heroImage, 'cloudinaryImage.0.public_id'))) {
    heroImage = {
      cloudinaryImage: [{ public_id: defaultImage }],
      imageAltText: get(productData, 'name'),
      imageTitleText: get(productData, 'name'),
    };
  }
  const handleLinkClick = useCallback(() => {
    productClick(productData);
  }, [productData]);

  if (isEmpty(productData)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <div className={classes.imageLink}>
          <Link to={fullPath} onClick={handleLinkClick}>
            <ProductLabels
              productData={productData}
              size="60"
              classes={{ label: classes.label, newLabel: classes.newLabel }}
            />
            <ProductAwards width="65" height="65" productData={productData} classes={{ awards: classes.awards }} />
            <ProductClassification
              width="45"
              height="45"
              productData={productData}
              classes={{ image: classes.ProductClassificationImage, root: classes.awards }}
            />
            <ThumbnailImage
              data={heroImage}
              className={classes.thumbnailImage}
              transformation="ProductThumbnailImage-IN-SM"
              width="auto"
              height="260"
              circle={false}
            />
          </Link>
        </div>

        <ProductCategories productData={productData} variant="card" className={classes.categories} />
        <Link to={fullPath} className={classes.link} onClick={handleLinkClick}>
          <Typography variant="h3" className={classes.name}>
            {get(productData, 'name')}
          </Typography>
        </Link>
      </div>
      <div>
        <Divider className={classes.divider} />
        <Typography variant="body1" className={classes.countryOfOrigin}>
          {get(productData, 'attributes.country_of_origin.value')}
        </Typography>
        <ProductMonopolyPrice productData={productData} className={classes.monopolyPrice} />

        <Visible visible={!hideMonopolyLink}>
          <ProductMonopolyLink productData={productData} />
        </Visible>

        <Visible visible={hideMonopolyLink}>
          <Button className={classes.readMoreButton} to={fullPath}>
            {t('ContentList.ReadMoreLinkButton.ProductPage')}
          </Button>
        </Visible>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  hideMonopolyLink: PropTypes.bool,
  defaultImage: PropTypes.string,
};

ProductCard.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  hideMonopolyLink: false,
  defaultImage: false,
};

export default withStyles(styles)(ProductCard);
