import get from 'lodash/get';

import {
  advancedFiltering,
  applyFilterWithManager,
  KlevuFetch,
  KlevuKMCSettings,
  KlevuLastSearches,
  KlevuTypeOfRecord,
  listFilters,
  personalisation,
  search,
  sendSearchEvent,
  suggestions,
  trendingProducts,
} from '@klevu/core';
import { map } from 'lodash';

export const getSettings = async () => {
  const settings = await KlevuKMCSettings();
  return settings.root ?? [];
};

export const doSearch = async (term, modifiers, limit, settings) => {
  if (get(settings, ['klevu_uc_userOptions', 'enablePersonalisationInSearch'])) {
    modifiers.push(personalisation());
  }

  const result = await KlevuFetch(
    search(
      term,
      {
        limit,
        typeOfRecords: [KlevuTypeOfRecord.Product],
      },
      ...modifiers,
    ),
    search(term, {
      id: 'cms',
      limit,
      typeOfRecords: [KlevuTypeOfRecord.Cms],
    }),
    suggestions(term),
  );

  const cmsResults = result.queriesById('cms');
  const productResults = result.queriesById('search');

  return {
    products: get(productResults, 'records', []),
    cms: get(cmsResults, 'records', []),
    suggestions: result.suggestionsById('suggestions')?.suggestions.map(i => i.suggest) ?? [],
    resultCount: {
      products: get(productResults, ['meta', 'totalResultsFound'], 0),
      content: get(cmsResults, ['meta', 'totalResultsFound'], 0),
      total: get(productResults, ['meta', 'totalResultsFound'], 0) + get(cmsResults, ['meta', 'totalResultsFound'], 0),
    },
  };
};

export const getSearchTermSuggestions = async term => {
  const result = await KlevuFetch(suggestions(term));
  return result.suggestionsById('suggestions')?.suggestions.map(i => i.suggest) ?? [];
};

export const getTrendingProducts = async (limit, modifiers, settings) => {
  if (get(settings, ['klevu_uc_userOptions', 'enablePersonalisationInSearch'])) {
    modifiers.push(personalisation());
  }

  const results = await KlevuFetch(
    trendingProducts(
      {
        limit,
      },
      ...modifiers,
    ),
  );

  return results.queriesById('trendingProducts')?.records ?? [];
};

export const getLastSearches = () => {
  return KlevuLastSearches.get();
};

export const getQuickSearchResults = async (term, settings) => {
  const results = await doSearch(term, [], 4, settings);
  return results;
};

export const getSearchResults = async (term, settings, manager, sorting, pageSize) => {
  const modifiers = [
    listFilters({
      filterManager: manager,
      rangeFilterSettings: get(settings, 'klevu_showPriceSlider', true)
        ? [
            {
              key: 'klevu_price',
              minMax: true,
            },
          ]
        : [],
    }),
    applyFilterWithManager(manager),
    sendSearchEvent(),
  ];

  if (get(settings, ['klevu_uc_userOptions', 'enablePersonalisationInSearch'])) {
    modifiers.push(personalisation());
  }

  const productFunctions = [
    search(
      term,
      {
        limit: pageSize,
        sort: sorting,
      },
      ...modifiers,
    ),
  ];

  const contentFunctions = [
    search(term, {
      id: 'cms',
      limit: pageSize,
      typeOfRecords: [KlevuTypeOfRecord.Cms],
    }),
  ];

  const productQueryResults = await KlevuFetch(...productFunctions);
  const contentQueryResults = await KlevuFetch(...contentFunctions);

  return {
    products: productQueryResults.queriesById('search') ?? [],
    cms: contentQueryResults.queriesById('cms') ?? [],
  };
};

export const getContentSearchResults = async (term, settings, manager, sorting, pageSize, defaultFilters = []) => {
  const advancedFilters = map(defaultFilters, filter => ({
    excludeValuesInResult: true,
    key: filter.key,
    singleSelect: false,
    valueOperator: 'INCLUDE',
    values: filter.values,
  }));

  const modifiers = [
    listFilters({
      filterManager: manager,
      filtersToReturn: {
        enabled: true,
      },
    }),
    advancedFiltering(advancedFilters),
    applyFilterWithManager(manager),
    sendSearchEvent(),
  ];

  if (get(settings, ['klevu_uc_userOptions', 'enablePersonalisationInSearch'])) {
    modifiers.push(personalisation());
  }

  const contentFunctions = [
    search(
      term,
      {
        id: 'cms',
        limit: pageSize,
        sort: sorting,
        typeOfRecords: [KlevuTypeOfRecord.Cms],
      },
      ...modifiers,
    ),
  ];

  const contentQueryResults = await KlevuFetch(...contentFunctions);

  const cmsResults = contentQueryResults.queriesById('cms');

  return {
    cms: cmsResults,
    suggestions: contentQueryResults.suggestionsById('suggestions')?.suggestions.map(i => i.suggest) ?? [],
    resultCount: {
      content: get(cmsResults, ['meta', 'totalResultsFound'], 0),
    },
  };
};
