import React, { Suspense } from 'react';

import isEqual from 'lodash/isEqual';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

export default function SearchResultItemPrice({ ...props }) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    const CommonSearchResultItem = React.lazy(() => import('./common'));
    return (
      <div>
        <Suspense fallback={<div>Laddar...</div>}>
          <CommonSearchResultItem {...props} />
        </Suspense>
      </div>
    );
  }
  if (isEqual(siteId, siteIds.Viinimaa)) {
    const ViinimaaSearchResultItem = React.lazy(() => import('./viinimaa'));
    return (
      <div>
        <Suspense fallback={<div>Ladataan...</div>}>
          <ViinimaaSearchResultItem {...props} />
        </Suspense>
      </div>
    );
  }

  return null;
}
