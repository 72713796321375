import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Toolbar, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import HamburgerMenu from '../../../navigation/HumburgerMenu';

import {
  viinimaaNavigationBreakpoint,
  blossaNavigationBreakpoint,
  gammeldanskNavigationBreakpoint,
  siteMaintenanceMode,
  siteIds,
} from '../../../../constants';

import { useMemoryList } from '../../../context/MemoryListContext';

import TopNavigation from '../../../navigation/TopNavigation';
import Logo from '../../../navigation/Logo';
import AppBar from '../Toolbar';
import MobileNavigation from '../../../navigation/MobileNavigation';
import Subscription from '../../../navigation/Subscription';
import B2BLink from '../../../navigation/B2BLink';
import QuickSearch from '../../../search/QuickSearch';
import MemoryListIconButton from '../../../memorylist/MemoryListIconButton';
import MemoryListDialog from '../../../memorylist/MemoryListDialog';
import LanguageSwitcher from '../../../navigation/LanguageSwitcher';
import RichText from '../../../shared/RichText';
import QuickSearchIconButton from '../../../search/QuickSearchIconButton';
import Visible from '../../../shared/Visible';

import { siteId } from '../../../../sites';

let navigationBreakpoint = viinimaaNavigationBreakpoint;
if (isEqual(siteId, siteIds.Blossa)) {
  navigationBreakpoint = blossaNavigationBreakpoint;
}
if (isEqual(siteId, siteIds.Gammeldansk)) {
  navigationBreakpoint = gammeldanskNavigationBreakpoint;
}

const styles = theme => ({
  root: {},
  navbar: {
    ...theme.palette.header.backgroundGradient,
    zIndex: '1',
    // justifyContent: 'space-between',
    minHeight: theme.palette.header.minHeight,
    background: theme.palette.header.background,
    paddingLeft: theme.palette.header.paddingYMobile,
    paddingRight: theme.palette.header.paddingYMobile,
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      paddingLeft: theme.palette.header.paddingYDesktop,
      paddingRight: theme.palette.header.paddingYDesktop,
    },
    '@media print': {
      borderBottom: 'none',
    },
  },
  logo: {
    padding: '8px 16px 8px 0',
  },
  navigation: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: ({ mobileMood }) => {
        return mobileMood ? 'none' : 'inline';
      },
    },
    '@media print': {
      display: 'none !important',
    },
  },
  linkswrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '@media print': {
      display: 'none !important',
    },
  },
  languageSwitcher: {
    display: ({ showLanguageSwitcher }) => {
      if (showLanguageSwitcher) {
        return 'flex';
      }
      return 'none';
    },
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: 'flex',
    },
    '@media print': {
      display: 'none !important',
    },
  },
  rightSide: {
    display: 'flex',
    paddingRight: '16px',
    '@media print': {
      display: 'none !important',
    },
  },
  subscriptionIcon: {
    display: 'none',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: 'inline',
    },
  },
  b2bLink: {
    display: 'none',
    [theme.breakpoints.up('500')]: {
      display: 'inline',
    },
  },

  hamburgerMenu: {
    height: '48px',
    padding: '10px',
    width: '48px',
    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      display: ({ mobileMood }) => {
        return mobileMood ? 'inline' : 'none';
      },
    },
    '@media print': {
      display: 'none !important',
    },
  },
  icon: {
    fontSize: '2rem',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    '& p': {
      marginBottom: 0,
    },
  },

  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto max-content min-content min-content',
    gridTemplateRows: 'auto auto',
    borderBottom: theme.palette.header.border.style,
    borderBottomColor: theme.palette.footer.border.color,

    [theme.breakpoints.up(navigationBreakpoint.Breakpoint)]: {
      gridTemplateRows: 'auto 0px',
    },

    '@media print': {
      borderBottom: 'none',
    },
  },
});

function Header({ classes, pageData, backgroundColor }) {
  const { products } = useMemoryList();

  const header = get(pageData, 'mainTheme.header');

  if (header) {
    const maintenanceMode = get(pageData, 'mainTheme.maintenanceMode');
    const isMaintenanceModeActive = isEqual(maintenanceMode, siteMaintenanceMode.ActiveMode);
    const headerElements = get(header, 'headerElements');
    const showLogo = !!headerElements.includes('Show logo');
    const showSearch = !!includes(headerElements, 'Show search');
    const showNavigation = !!includes(headerElements, 'Show navigation');
    const showNewsletterSubscription = !!headerElements.includes('Show newsletter subscription');
    const headerContent = get(header, 'headerContent');

    return (
      <div>
        <AppBar className={classes.root} pageData={pageData} color={backgroundColor}>
          <Toolbar className={clsx(classes.navbar, classes.gridContainer)}>
            <div>{showLogo && <Logo pageData={pageData} className={classes.logo} />}</div>
            <div>
              {!isMaintenanceModeActive && showNavigation && (
                <TopNavigation pageData={pageData} className={classes.navigation} />
              )}
            </div>
            <div className={classes.linkswrapper}>
              <LanguageSwitcher className={classes.languageSwitcher} pageData={pageData} />
              {!isMaintenanceModeActive && showNewsletterSubscription && (
                <Subscription className={classes.subscriptionIcon} />
              )}

              {products.length > 0 ? (
                <MemoryListIconButton className={clsx(classes.icon, classes.memorylistIcon)} />
              ) : null}

              {headerContent && <RichText pageData={pageData} textData={headerContent} className={classes.content} />}

              <B2BLink className={classes.b2bLink} />
            </div>

            <QuickSearchIconButton />

            <div className={classes.hamburgerMenuWrapper}>
              {showNavigation && <HamburgerMenu className={classes.hamburgerMenu} />}
            </div>
          </Toolbar>
          <Visible visible={!isMaintenanceModeActive && showSearch}>
            <QuickSearch pageData={pageData} />
          </Visible>
        </AppBar>
        {showNavigation && <MobileNavigation pageData={pageData} />}
        <MemoryListDialog pageData={pageData} />
      </div>
    );
  }

  return null;
}

Header.propTypes = {
  backgroundColor: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showLanguageSwitcher: PropTypes.bool,
  mobileMood: PropTypes.bool,
};

Header.defaultProps = {
  backgroundColor: 'transparent',
  classes: {},
  className: null,
  pageData: null,
  showLanguageSwitcher: false,
  mobileMood: false,
};

export default withStyles(styles)(Header);
