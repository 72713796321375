/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import Visible from '../../shared/Visible';
import CloudinaryImage from '../../shared/CloudinaryImage';
import ImageSpotLink from '../../shared/ImageSpotLink/ImageSpotLink';
import { isSSR } from '../../../utils/windowUtils';

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  imageHover: {
    cursor: 'pointer',
    '&:hover #_image': {
      transform: ' scale(1.15)',
    },
  },
  image: {
    transition: 'all 2s ease',
  },

  content: {
    position: 'absolute',
    width: '100%',
    left: '0',
    bottom: '0',
    transform: 'translate(-0%, -0%)',
  },

  description: {
    alignSelf: 'flex-end',
    padding: '30px 30px 14px 30px',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      padding: '40px 50px 24px 50px',
    },
  },
  descriptionDark: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: `${theme.palette.text.light} !important`,
    '& > *': {
      color: `${theme.palette.text.light} !important`,
    },
    '& p > a': {
      color: `${theme.palette.text.light} !important`,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  descriptionLight: { backgroundColor: 'rgba(255,255,255,0.6)' },
});

function Image({ classes, className, data, richText: RichText, showDescription, width, height, transformation }) {
  const altText = get(data, 'imageAltText');
  const titleText = get(data, 'imageTitleText');
  const description = get(data, 'description');
  const descriptionStyle = get(data, 'descriptionStyle.title');
  const imageLink = get(data, 'imageLink');
  const openLinkTo = get(data, 'openLinkTo');
  const publicId = get(data, 'cloudinaryImage.0.public_id');

  const imageSpotLinks = get(data, 'imageSpotLink');

  const variants = [
    {
      transformation,
      parameters: {
        c: 'fill',
        h: height,
      },
      width,
    },
  ];

  const handelClick = React.useCallback(() => {
    if (!isSSR() && !isEmpty(imageLink)) {
      window.open(imageLink, !openLinkTo ? '_blank' : null, 'noreferrer');
    }
  }, [imageLink, openLinkTo]);

  if (isEmpty(publicId)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(!isEmpty(imageLink) ? classes.imageHover : null)} onClick={handelClick}>
        <div id="_image" className={classes.image}>
          <CloudinaryImage
            publicId={publicId}
            variants={variants}
            alt={altText}
            title={titleText}
            width={width}
            height={height}
          />
        </div>

        <Visible hidden={isEmpty(get(description, 'raw')) || !showDescription}>
          <div className={classes.content}>
            <RichText
              textData={description}
              className={clsx(
                classes.description,
                isEqual(descriptionStyle, 'Inspirational overlay - Light')
                  ? classes.descriptionLight
                  : classes.descriptionDark,
              )}
            />
          </div>
        </Visible>
        {map(imageSpotLinks, spotLinkData => {
          const id = get(spotLinkData, 'id');
          return <ImageSpotLink data={data} spotLinkData={spotLinkData} richText={RichText} key={id} />;
        })}
      </div>
    </div>
  );
}

Image.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  richText: PropTypes.object,
  showDescription: PropTypes.bool,
  transformation: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Image.defaultProps = {
  classes: {},
  className: null,
  data: null,
  richText: null,
  showDescription: false,
  transformation: 'CardImage-LG-LG',
  width: '608',
  height: '270',
};

export default withStyles(styles)(Image);
