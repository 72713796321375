import React from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';
import { createCloudinaryImageUrl } from '../../../utils/cloudinaryUtils';

import CommonSearchResultItem from './common';

export default function SearchResultItem({ placeholderImage, ...otherProps }) {
  if (isEqual(siteId, siteIds.Viinimaa) || isEqual(siteId, siteIds.Folkofolk)) {
    const publicId = get(placeholderImage, ['cloudinaryImage', '0', 'public_id'], '');
    const placeholderImageUrl = createCloudinaryImageUrl(
      '',
      publicId,
      'c_fill,h_130,w_130,r_max',
      'auto',
      'auto:good',
      'custom',
    );
    return <CommonSearchResultItem {...otherProps} placeholderImage={placeholderImageUrl} />;
  }

  return null;
}

SearchResultItem.propTypes = {
  placeholderImage: PropTypes.object,
};

SearchResultItem.defaultProps = {
  placeholderImage: {},
};
