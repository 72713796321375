import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaProductSliderModule from './viinimaa';

function ProductSliderModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductSliderModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductSliderModule {...props} />;
  }

  return null;
}

export default ProductSliderModule;
