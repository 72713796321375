import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import SocialWinesEventCalendarModule from './socialwines';

function EventCalendarModule(props) {
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <SocialWinesEventCalendarModule {...props} />;
  }

  return null;
}

export default EventCalendarModule;
