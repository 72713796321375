import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaQuickSearchIconButton from './viinimaa';
import FolkofolkQuickSearchIconButton from './folkofolk';

function QuickSearchIconButton(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaQuickSearchIconButton {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkQuickSearchIconButton {...props} />;
  }

  return null;
}

export default QuickSearchIconButton;
