import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual, isNil, toLower } from 'lodash';
import { withStyles } from '@material-ui/core';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShoppingCart from '@material-ui/icons/ShoppingCartOutlined';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Launch from '@material-ui/icons/Launch';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import Button from '../../../shared/Button';

const styles = theme => ({
  root: {
    ...theme.palette.button,
    margin: '3px',
    color: ({ data }) => {
      let color = null;
      if (get(data, 'buttonStyle')) {
        color = get(data, 'buttonSecondaryColor') || null;
      } else {
        color = get(data, 'buttonPrimaryColor') || null;
      }
      return color;
    },
    background: ({ data }) => {
      const backgroundColor = get(data, 'buttonPrimaryColor');
      return get(data, 'buttonStyle') && backgroundColor ? backgroundColor : null;
    },
    borderColor: ({ data }) => {
      return get(data, 'buttonPrimaryColor') || null;
    },
    '&:hover': {
      color: ({ data }) => {
        let color = null;
        if (get(data, 'buttonStyle')) {
          color = get(data, 'buttonPrimaryColor') || null;
        } else {
          color = get(data, 'buttonSecondaryColor') || null;
        }
        return color;
      },
      background: ({ data }) => {
        let color = null;
        if (get(data, 'buttonStyle')) {
          color = get(data, 'buttonSecondaryColor') || null;
        }
        return color;
      },
      borderColor: ({ data }) => {
        return get(data, 'buttonSecondaryColor') || null;
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  paragraph: {
    display: 'inline-block',
  },
  icon: { color: 'inherit', fontSize: '24px!important' },
});

function Icons({ classes, type, ...otherProps }) {
  switch (type) {
    case 'Chevron right':
      return <ChevronRightIcon className={classes.icon} {...otherProps} />;
    case 'Cart':
      return <ShoppingCart className={classes.icon} {...otherProps} />;

    case 'Arrow right':
      return <ArrowForward className={classes.icon} {...otherProps} />;

    case 'External link':
      return <Launch className={classes.icon} {...otherProps} />;

    case 'Info':
      return <InfoOutlined className={classes.icon} {...otherProps} />;

    default:
      return null;
  }
}

Icons.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
};

Icons.defaultProps = {
  classes: {},
  type: null,
};

function ButtonLinkModule({ classes, className, data, endIcon }) {
  const buttonText = get(data, 'buttonText');
  const openLinkTo = get(data, 'openLinkTo');
  const containedStyle = get(data, 'buttonStyle');
  const buttonAppearance = get(data, 'buttonAppearance');

  const icon = get(data, 'icon');
  const iconPlacement = get(data, 'iconPlacement');
  const target = openLinkTo ? '_self' : '_blank';

  const variant = useMemo(() => {
    if (!isEmpty(buttonAppearance)) {
      return toLower(buttonAppearance);
    }
    if (!containedStyle) {
      return 'outlined';
    }
    return 'contained';
  }, [buttonAppearance, containedStyle]);

  let buttonEndIcon = endIcon;
  let buttonStartIcon = null;
  if (!isEmpty(icon)) {
    buttonStartIcon = null;
    buttonEndIcon = <Icons classes={classes} type={icon} />;
    if (isEqual(iconPlacement, 'Before')) {
      buttonEndIcon = null;
      buttonStartIcon = <Icons classes={classes} type={icon} />;
    }
  }

  const options = useMemo(() => {
    return {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <span className={classes.paragraph}>{children}</span>,
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const fullPath = get(node, 'data.target.fullPath');
          return (
            <Button
              to={fullPath}
              target={target}
              size="small"
              variant={variant}
              color="primary"
              endIcon={buttonEndIcon}
              startIcon={buttonStartIcon}
              className={clsx(classes.root, className)}>
              {children}
            </Button>
          );
        },
        [INLINES.HYPERLINK]: (node, children) => {
          const uri = get(node, 'data.uri');
          return (
            <Button
              to={uri}
              target={target}
              size="small"
              variant={variant}
              color="primary"
              endIcon={buttonEndIcon}
              startIcon={buttonStartIcon}
              className={clsx(classes.root, className)}>
              {children}
            </Button>
          );
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          const title = get(node, 'content[0].value');
          const url = get(node, 'data.target.file.url');
          return (
            <Button
              to={url}
              variant={variant}
              color="primary"
              target={target}
              size="small"
              endIcon={buttonEndIcon}
              startIcon={buttonStartIcon}
              className={clsx(classes.root, className)}>
              {title}
            </Button>
          );
        },
      },
    };
  }, [classes, className, variant, target]);

  return renderRichText(isNil(buttonText) ? { raw: null } : buttonText, options);
}

ButtonLinkModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  endIcon: PropTypes.element,
};

ButtonLinkModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  endIcon: null,
};

export default withStyles(styles)(ButtonLinkModule);
