import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles, Grid, Box } from '@material-ui/core';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { useWindowSize } from '../../hooks/useWindowSize';
import { breakPoints } from '../../../constants';
import { getImageHeight, getImageWidth } from './settings';

import Image from './Image';

const styles = () => ({
  root: {
    display: 'block',
  },
  img1: {},
  img2: {},
  img3: {
    marginTop: '16px',
  },
  img4: {},
  img5: {
    marginTop: '16px',
  },
});

function LayoutTwo({ classes, className, data, richText: RichText }) {
  const [breakpoint, setBreakpoint] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width < breakPoints.md) {
        setBreakpoint('mobile');
      } else if (width < breakPoints.lg) {
        setBreakpoint('tablet');
      } else {
        setBreakpoint('desktop');
      }
    } else {
      setBreakpoint('mobile');
    }
    return () => {
      return null;
    };
  }, [width]);
  const inspirationalElements = get(data, 'inspirationalElements');
  const image1 = get(inspirationalElements, '0');
  const image2 = get(inspirationalElements, '1');
  const image3 = get(inspirationalElements, '2');
  const image4 = get(inspirationalElements, '3');
  const image5 = get(inspirationalElements, '4');

  return (
    <div className={clsx(classes.root, className)}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Image
              data={image1}
              richText={RichText}
              className={classes.img1}
              showDescription
              transformation=""
              height={getImageHeight('lg', breakpoint)}
              width={getImageWidth('lg', breakpoint)}
            />
          </Grid>

          <Grid container item spacing={0} xs={6} md={3}>
            <Grid item xs={12} md={12}>
              <Image
                data={image2}
                richText={RichText}
                className={classes.img2}
                showDescription
                height={getImageHeight('md', breakpoint)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Image
                data={image3}
                richText={RichText}
                className={classes.img3}
                height={getImageHeight('sm', breakpoint)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={6} md={3}>
            <Grid item xs={12} md={12}>
              <Image
                data={image4}
                richText={RichText}
                className={classes.img4}
                height={getImageHeight('sm', breakpoint)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Image
                data={image5}
                richText={RichText}
                className={classes.img5}
                showDescription
                height={getImageHeight('md', breakpoint)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

LayoutTwo.propTypes = {
  classes: PropTypes.object,
  richText: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

LayoutTwo.defaultProps = {
  classes: {},
  className: null,
  richText: null,
  data: null,
};

export default withStyles(styles)(LayoutTwo);
