import React from 'react';

import { isEqual } from 'lodash';

import { siteIds, subscribeNewsletterPage } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaFormModule from './viinimaa';

function FormModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaFormModule {...props} subscribeNewsletterPageURL={subscribeNewsletterPage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaFormModule {...props} subscribeNewsletterPageURL={subscribeNewsletterPage.Folkofolk} />;
  }

  return null;
}

export default FormModule;
