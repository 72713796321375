import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaContactPersonModule from './viinimaa';
import WennercoContactPersonModule from './Wennerco';
import KoskenkorvaContactPersonModule from './koskenkorva';
import WineworldFinlandContactPersonModule from './wineworld-finland';
import SocialwinesContactPersonModule from './socialwines';

function ContactPersonModule(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaContactPersonModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaContactPersonModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <WennercoContactPersonModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaContactPersonModule {...props} />;
  }
  if (isEqual(siteId, siteIds.WineworldFinland)) {
    return <WineworldFinlandContactPersonModule {...props} />;
  }
  if (isEqual(siteId, siteIds.Socialwines)) {
    return <SocialwinesContactPersonModule {...props} />;
  }
  if (brandSite) {
    return <ViinimaaContactPersonModule {...props} />;
  }

  return null;
}

export default ContactPersonModule;
