import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductClassification from './viinimaa';

function ProductClassification(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductClassification {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductClassification linkUrl="/artiklar/aktiviteter/vad-betyder-markningen" {...props} />;
  }

  return null;
}

export default ProductClassification;
