import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { withStyles, Divider, Typography, Container } from '@material-ui/core';

import { productClick } from '../../../../../utils/analyticUtils';

import ThumbnailImage from '../../../ThumbnailImage';
import Link from '../../../../navigation/Link';
import ProductCategories from '../../ProductCategories';
import ProductMonopolyLink from '../../ProductMonopolyLink';
import ProductMonopolyPrice from '../../ProductMonopolyPrice';
import ProductLabels from '../../ProductLabels';
import ProductAwards from '../../ProductAwards';
import ProductClassification from '../../ProductClassification';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  imageProdcut: {
    position: 'relative',
    width: '100%',
  },
  label: {
    margin: '0px',
  },
  newLabel: {
    padding: '0px 0px',
    fontSize: '0.75rem',
  },
  awards: {
    padding: '0px 0px',
  },
  ProductClassificationImage: {
    margin: theme.spacing(1, 0),
  },

  right: {
    padding: theme.spacing(3, 0, 3, 2),
    flexBasis: '50%',
    maxWidth: '50%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      flexBasis: '60%',
      maxWidth: '60%',
    },
  },

  thumbnailImage: {
    margin: theme.spacing(0, 'auto', 2, 'auto'),
    '@media print': {
      height: 'auto',
      maxHeight: '400px',
      width: 'auto',
    },
  },
  categories: {
    marginBottom: theme.spacing(1),
  },
  name: {
    marginBottom: theme.spacing(2),
    color: 'inherit',
  },
  link: {
    color: 'inherit',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
});

function ProductDescriptionCard({ classes, className, productData, description, defaultImage }) {
  const fullPath = get(productData, 'fullPath');
  let heroImage = get(productData, 'heroImage');
  if (isEmpty(get(heroImage, 'cloudinaryImage.0.public_id'))) {
    heroImage = {
      cloudinaryImage: [{ public_id: defaultImage }],
      imageAltText: get(productData, 'name'),
      imageTitleText: get(productData, 'name'),
    };
  }
  const handleLinkClick = useCallback(() => {
    productClick(productData);
  }, [productData]);

  if (isEmpty(productData)) {
    return null;
  }

  return (
    <Container className={clsx(classes.root, className)} maxWidth="md">
      <div className={classes.left}>
        <div className={classes.imageProdcut}>
          <ProductLabels
            productData={productData}
            size="60"
            classes={{ label: classes.label, newLabel: classes.newLabel }}
          />
          <ProductAwards width="65" height="65" productData={productData} classes={{ awards: classes.awards }} />
          <ProductClassification
            width="45"
            height="45"
            productData={productData}
            classes={{ image: classes.ProductClassificationImage, root: classes.awards }}
          />
          <Link to={fullPath} onClick={handleLinkClick}>
            <ThumbnailImage
              data={heroImage}
              className={classes.thumbnailImage}
              transformation="ProductThumbnailImage-IN-SM"
              width="auto"
              height="260"
              circle={false}
            />
          </Link>
        </div>

        <ProductMonopolyLink productData={productData} />
      </div>
      <div className={classes.right}>
        <ProductCategories productData={productData} variant="card" className={classes.categories} />
        <Link to={fullPath} className={classes.link} onClick={handleLinkClick}>
          <Typography variant="h3" className={classes.name}>
            {get(productData, 'name')}
          </Typography>
        </Link>
        <ProductMonopolyPrice productData={productData} />
        <Divider className={classes.divider} />
        {description}
      </div>
    </Container>
  );
}

ProductDescriptionCard.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  description: PropTypes.object,
  defaultImage: PropTypes.string,
};

ProductDescriptionCard.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  description: null,
  defaultImage: false,
};

export default withStyles(styles)(ProductDescriptionCard);
