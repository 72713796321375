/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import take from 'lodash/take';
import clsx from 'clsx';

import { KlevuDomEvents, KlevuListenDomEvent } from '@klevu/core';

import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { bindPopper, bindFocus, usePopupState } from 'material-ui-popup-state/hooks';

import { ArrowForward, Close, Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../hooks/useWindowSize';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import ShowAllButton from './ShowAllButton';
import SearchSuggestions from '../../SearchSuggestions';
import { viinimaaNavigationBreakpoint } from '../../../../constants';
import { useKlevuSearch } from '../../../context/KlevuSearchContext';
import { searchUsage } from '../../../../utils/analyticUtils';
import Link from '../../../navigation/Link';

import {
  getQuickSearchResults,
  getTrendingProducts,
  getSettings,
  getLastSearches,
  getSearchTermSuggestions,
} from '../../../../utils/klevuUtils';
import SearchResultItem from '../../SearchResultItem';

const styles = theme => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  resultSection: {},
  productItem: {
    maxWidth: '50%',
    flexBasis: '50%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },
  root: {
    width: '100%',
    '@media print': {
      display: 'none',
    },
  },
  inputToolbar: {
    ...theme.palette.search.quickSearch.inputContainer,
    transition: 'all 0.1s ease-in-out',
    minHeight: 0,
    paddingLeft: theme.palette.header.paddingYMobile,
    paddingRight: theme.palette.header.paddingYMobile,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
      paddingLeft: theme.palette.header.paddingYDesktop,
      paddingRight: theme.palette.header.paddingYDesktop,
    },
  },
  form: {
    width: 'fill-available',
  },
  klevuInput: {
    width: '100%',
    backgroundColor: 'transparent',
    marginBottom: 0,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    '@media print': {
      display: 'none',
    },
  },
  closeButton: {
    ...theme.palette.search.quickSearch.hideButton,
    position: 'absolute !important',
    right: theme.spacing(1),
    top: theme.spacing(0),
    [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
      right: theme.spacing(4),
      top: theme.spacing(1),
    },
  },
  visible: {},
  hidden: {
    maxHeight: 0,
    overflow: 'hidden',
    padding: 0,
  },
  endAdornment: {
    paddingRight: 0,
  },
  entryName: {
    fontSize: '1.125rem',
  },
  titleLink: {
    color: 'inherit',
    display: 'block',
    width: '100%',
  },
});

/**
 *
 * @param props
 * @returns
 */
function QuickSearch({ classes, pageData }) {
  const { isInputOpen, updateIsInputOpen } = useKlevuSearch();
  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [lastSearches, setLastSearches] = useState(getLastSearches);
  const [suggestions, setSuggestions] = useState([]);
  const [content, setContent] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperMaxHeight, setPopperMaxHeight] = useState(null);
  const [klevuSettings, setKlevuSettings] = useState();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalContentCount, setTotalContentCount] = useState(0);

  const { width } = useWindowSize();
  const isMobile = width <= 600;

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'searchPopup',
    disableAutoFocus: true,
  });

  const { t } = useTranslation();

  const searchPageUrl = get(pageData, 'searchPage.fullPath');

  const placeholderImage = get(pageData, ['mainTheme', 'themeStyles', 'favicon']);

  const cleanSearchResultState = () => {
    setProducts([]);
    setContent([]);
    setSuggestions([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSearch = async term => {
    setSearchInProgress(true);
    if (term.length < 3) {
      cleanSearchResultState();
      const searchTermSuggestions = await getSearchTermSuggestions(term);
      setSuggestions(take(searchTermSuggestions, isMobile ? 2 : 5));
    } else {
      const results = await getQuickSearchResults(term, klevuSettings);
      const searchSuggestions = take(get(results, 'suggestions', []), isMobile ? 2 : 5);

      setProducts(get(results, 'products', []));
      setTotalProductCount(get(results, ['resultCount', 'products']));
      setContent(get(results, 'cms', []));
      setTotalContentCount(get(results, ['resultCount', 'content']));
      setSuggestions(searchSuggestions);
      searchUsage(term, get(results, ['resultCount', 'total'], 0));
    }

    setSearchInProgress(false);
  };

  const handleLastSearchesUpdate = () => {
    if (get(klevuSettings, 'klevu_showRecentSerches')) {
      setLastSearches(Array.from(getLastSearches()).reverse());
    }
  };

  const handleSearchInputFocus = async () => {
    handleLastSearchesUpdate();

    if (get(klevuSettings, ['klevu_uc_userOptions', 'showTrendingProducts'])) {
      const results = await getTrendingProducts(4, [], klevuSettings);
      setTrendingProducts(results);
    }
  };

  const clearSearchResults = () => {
    setProducts(0);
    setContent(0);
  };

  const handleCloseButtonClick = useCallback(() => {
    popupState.close();
    updateIsInputOpen(false);
    setSearchValue('');
    clearSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIsInputOpen]);

  const onSearchClick = () => {
    popupState.close();
    window.location.href = `${searchPageUrl}?q=${searchValue}`;
  };

  const onKeydown = event => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  const onSearchChange = event => {
    setSearchValue(event.target.value);
    doSearch(event.target.value);
  };

  const fetchKlevuSettings = async () => {
    const settings = await getSettings();
    setKlevuSettings(settings);
  };

  useEffect(() => {
    fetchKlevuSettings();
    const stop = KlevuListenDomEvent(KlevuDomEvents.LastSearchUpdate, handleLastSearchesUpdate);

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusParams = bindFocus(popupState);

  const params = {
    onFocus: event => {
      if (isInputOpen) {
        focusParams.onFocus(event);
      } else {
        setTimeout(() => {
          focusParams.onFocus(event);
        }, 500);
      }

      setAnchorEl(event.currentTarget.parentElement);

      const rect = event.currentTarget.getBoundingClientRect();
      setPopperMaxHeight(window.innerHeight - rect.bottom - 100);

      handleSearchInputFocus();
    },
  };

  const handleClickAway = () => {
    popupState.close();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div className={classes.root}>
          <Toolbar className={clsx(classes.inputToolbar, isInputOpen ? classes.visible : classes.hidden)}>
            <div className={classes.closeButtonContainer}>
              <IconButton
                color="primary"
                aria-label={t('KlevuSearch.hideSearch')}
                component="span"
                className={classes.closeButton}
                onClick={handleCloseButtonClick}>
                <Close />
              </IconButton>
            </div>
            <Container maxWidth="md" disableGutters className={classes.container}>
              <TextField
                id="klevu-search"
                value={searchValue}
                variant="outlined"
                onKeyDown={onKeydown}
                onChange={onSearchChange}
                placeholder={t('KlevuSearch.input.placeholder')}
                className={classes.klevuInput}
                inputProps={{
                  autoComplete: 'off',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.icon} />
                    </InputAdornment>
                  ),
                  endAdornment: searchValue.length > 0 && (
                    <InputAdornment position="end">
                      <Button href={`${searchPageUrl}?q=${searchValue}`} color="primary" endIcon={<ArrowForward />}>
                        {t('KlevuSearch.viewAllButton.label')}
                      </Button>
                    </InputAdornment>
                  ),
                  classes: {
                    adornedEnd: classes.endAdornment,
                  },
                }}
                {...params}
              />
            </Container>
          </Toolbar>
        </div>
        <Popper
          {...bindPopper(popupState)}
          placement="bottom"
          disablePortal
          anchorEl={anchorEl}
          style={{
            width: anchorEl?.clientWidth,
            zIndex: 99999,
          }}>
          <Paper
            elevation={8}
            style={{
              padding: '28px 20px 0',
              display: 'flex',
              overflow: 'auto',
              position: 'relative',
              maxHeight: `${popperMaxHeight}px`,
            }}>
            <Box style={{ width: '100%' }}>
              {searchInProgress ? (
                <Box padding={5}>
                  <LoadingIndicator />
                </Box>
              ) : products.length > 0 || content.length > 0 || suggestions.length > 0 ? (
                <>
                  {suggestions && suggestions.length > 0 && (
                    <Box className={classes.resultSection}>
                      <SearchSuggestions
                        searchPage={searchPageUrl}
                        suggestions={suggestions}
                        title={t('KlevuSearch.searchSuggestions')}
                      />
                      <Divider className={classes.divider} />
                    </Box>
                  )}

                  {products.length > 0 && (
                    <Box className={classes.resultSection}>
                      <Link to={`${searchPageUrl}?q=${searchValue}&tab=products`} className={classes.titleLink}>
                        <Typography variant="h3">{`${t('KlevuSearch.products')} (${totalProductCount})`}</Typography>
                      </Link>

                      <List disablePadding>
                        {products.map((product, i) => (
                          <ListItem key={get(product, 'sku', i)} disableGutters style={{ padding: 0 }}>
                            <SearchResultItem
                              item={product}
                              settings={klevuSettings}
                              classes={{ title: classes.entryName }}
                            />
                          </ListItem>
                        ))}
                      </List>

                      <Box display="flex" justifyContent="center" alignItems="center" paddingTop={0} paddingBottom={2}>
                        <ShowAllButton searchTerm={searchValue} searchPage={searchPageUrl} tab="products" />
                      </Box>

                      <Divider className={classes.divider} />
                    </Box>
                  )}

                  {content.length > 0 && (
                    <>
                      <Box className={classes.resultSection}>
                        <Link to={`${searchPageUrl}?q=${searchValue}&tab=content`} className={classes.titleLink}>
                          <Typography variant="h3">{`${t(
                            'KlevuSearch.cmsContent',
                          )} (${totalContentCount})`}</Typography>
                        </Link>

                        <List disablePadding>
                          {content.map((entry, i) => (
                            <ListItem key={get(entry, 'id', i)} disableGutters>
                              <SearchResultItem
                                item={entry}
                                classes={{ title: classes.entryName }}
                                placeholderImage={placeholderImage}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Box display="flex" justifyContent="center" alignItems="center" paddingTop={3} paddingBottom={3}>
                        <ShowAllButton searchTerm={searchValue} searchPage={searchPageUrl} tab="content" />
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  {searchValue &&
                    get(klevuSettings, ['klevu_uc_userOptions', 'noResultsOptions', 'messages'], []).map(
                      (message, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Typography variant="body1" paragraph key={i}>
                          {get(message, 'message')}
                        </Typography>
                      ),
                    )}

                  {get(klevuSettings, ['klevu_uc_userOptions', 'noResultsOptions', 'showPopularKeywords']) && (
                    <Box className={classes.resultSection}>
                      <SearchSuggestions
                        searchPage={searchPageUrl}
                        suggestions={klevuSettings.klevu_webstorePopularTerms}
                        title={t('KlevuSearch.popularSearches')}
                      />
                    </Box>
                  )}

                  {trendingProducts.length > 0 && (
                    <Box className={classes.resultSection}>
                      <Typography variant="h3">{t('KlevuSearch.trendingProducts')}</Typography>
                      <List disablePadding>
                        {trendingProducts.map((product, i) => (
                          <ListItem key={get(product, 'sku', i)} disableGutters style={{ padding: 0 }}>
                            <SearchResultItem
                              item={product}
                              settings={klevuSettings}
                              classes={{ title: classes.entryName }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}

                  {lastSearches.length > 0 && (
                    <Box className={classes.resultSection} mt={3} mb={3}>
                      <SearchSuggestions
                        searchPage={searchPageUrl}
                        suggestions={lastSearches}
                        title={t('KlevuSearch.userLastSearches')}
                        isObject
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

QuickSearch.propTypes = {
  classes: PropTypes.object,
  pageData: PropTypes.object,
};

QuickSearch.defaultProps = {
  classes: {},
  pageData: {},
};

export default withStyles(styles)(QuickSearch);
