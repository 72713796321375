import { Card, CardActionArea, Divider, Typography, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import Price from '../../SearchResultItemPrice';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    height: '100%',
  },
  actionArea: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  imageContainer: {
    display: 'flex',
    height: ({ imageHeight }) => {
      return `${imageHeight}px`;
    },
    margin: theme.spacing(0, 'auto', 2, 'auto'),
  },
  thumbnailImage: {
    display: 'block',
    height: 'auto',
    margin: 'auto',
    maxHeight: ({ imageHeight }) => {
      return `${imageHeight}px`;
    },
    maxWidth: '100%',
    width: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: '6px',
  },
  countryOfOrigin: {
    color: theme.palette.common.mediumGray,
    marginBottom: theme.spacing(2),
  },
  name: {},
  price: {
    minHeight: theme.spacing(3),
  },
});
function GridItem({ classes, imageHeight, item, settings }) {
  if (!item) return null;

  const imageUrl = get(item, 'imageUrl') || get(settings, ['klevu_uc_userOptions', 'noImageUrl']);
  const name = get(item, 'name');
  const url = get(item, 'url');
  const countryOfOrigin = get(item, 'country_of_origin');

  return (
    <Card className={classes.root}>
      <CardActionArea href={url} className={classes.actionArea}>
        <div>
          <div className={classes.imageContainer}>
            <img src={imageUrl} alt={name} height={imageHeight} className={classes.thumbnailImage} />
          </div>

          <Typography variant="h4" className={classes.name}>
            {name}
          </Typography>
        </div>

        <div>
          <Divider className={classes.divider} />

          <Typography variant="body1" className={classes.countryOfOrigin}>
            {countryOfOrigin}
          </Typography>

          <Price item={item} settings={settings} className={classes.price} />
        </div>
      </CardActionArea>
    </Card>
  );
}

GridItem.propTypes = {
  classes: PropTypes.object,
  imageHeight: PropTypes.number,
  item: PropTypes.object,
  settings: PropTypes.object,
};

GridItem.defaultProps = {
  classes: {},
  imageHeight: 80,
  item: {
    currency: '',
    imageUrl: '',
    name: '',
    salePrice: '',
  },
  settings: {},
};

export default withStyles(styles)(GridItem);
